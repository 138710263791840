import type {MetaFunction} from '@remix-run/cloudflare';

export const blogMeta: MetaFunction = ({data}: any) => {
  const hrefLangs = data?.hreflangs || [];

  return [
    {
      name: 'robots',
      content: 'index, follow, max-image-preview:large',
    },
    ...hrefLangs,
  ];
};
